<template>
    <div>
        <h1 class="title is-4">
            <template v-if="$route.params.id && ChoosedKey">
                {{ $t("Interface.Key.Share.Title") + ` "${ChoosedKey.description}"` }}
            </template>
            <template v-else>{{ $t("Interface.Key.Share.Title") }}</template>
        </h1>
        <ValidationObserver
            v-slot="{ handleSubmit, invalid }"
            tag="form"
            class="form box"
        >
            <div class="columns is-multiline">
                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-full"
                    :name="$t('Interface.Key.Share.List')"
                >
                    <div class="field">
                        <label class="label">
                            {{ $t("Interface.Key.Share.List") }}
                        </label>
                        <textarea
                            v-model="Form.List"
                            rows="5"
                            class="textarea"
                            :class="{ 'is-danger': errors[0], 'is-success': valid }"
                        />
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </div>
                </ValidationProvider>
            </div>
            <button
                type="submit"
                class="button is-theme"
                :disabled="invalid"
                @click.prevent="handleSubmit(submit)"
            >
                {{ $t("Interface.Button.Submit") }}
            </button>
        </ValidationObserver>
        <b-loading
            :is-full-page="true"
            :active.sync="IsLoading"
            :can-cancel="false"
        />
    </div>
</template>

<script>
// import Helpers from "@/utils/Helpers"
import LoadingState from "@/mixins/LoadingState"
import {
    KEY_LIST_REQUEST,
    KEY_SHARE_REQUEST
} from "@/store/actions/key"

export default {
    name: "KeyShare",
    mixins: [LoadingState],
    data() {
        return {
            Form: {
                List: null,
                File: null
            }
        }
    },
    computed: {
        ChoosedKey() {
            return this.$store.getters.GET_KEY_BY_ID(parseInt(this.$route.params.id, 10))
        }
    },
    mounted() {
        if (!this.$store.getters.IS_KEYS_LOADED) {
            this.switchLoading()
            this.$store.dispatch(KEY_LIST_REQUEST)
                .finally(() => this.switchLoading())
        }
    },
    methods: {
        submit() {
            this.switchLoading()
            const Form = {
                emails: this.Form.List
            }
            this.$store.dispatch(KEY_SHARE_REQUEST, { Form, Id: this.$route.params.id })
                .then(() => {
                    this.$store.dispatch(KEY_LIST_REQUEST)
                        .then(() => this.$router.push({ name: "Key-List" }))
                })
                .finally(() => this.switchLoading())
        }
    },
    metaInfo() {
        return {
            title: this.$t("Interface.Key.Share.Title")
        }
    }
}
</script>
